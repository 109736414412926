
import { importSync as i } from '@embroider/macros';
let d = window.define;
import "@onwardcare/ui-components/-embroider-implicit-modules.js";
import "ember-cli-babel/-embroider-implicit-modules.js";
import "ember-cli-bundle-analyzer/-embroider-implicit-modules.js";
import "ember-cli-mirage/-embroider-implicit-modules.js";
import "ember-concurrency/-embroider-implicit-modules.js";
import "ember-data/-embroider-implicit-modules.js";
import "ember-data-resources/-embroider-implicit-modules.js";
import "ember-file-upload/-embroider-implicit-modules.js";
import "ember-modifier/-embroider-implicit-modules.js";
import "ember-page-title/-embroider-implicit-modules.js";
import "ember-qunit/-embroider-implicit-modules.js";
import "ember-resources/-embroider-implicit-modules.js";
import "ember-set-helper/-embroider-implicit-modules.js";
import "ember-simple-auth/-embroider-implicit-modules.js";
import "ember-truth-helpers/-embroider-implicit-modules.js";
import "ember-velcro/-embroider-implicit-modules.js";
import "tracked-built-ins/-embroider-implicit-modules.js";
import "ember-cli-inject-live-reload/-embroider-implicit-modules.js";
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "@adopted-ember-addons/ember-stripe-elements/-embroider-implicit-modules.js";
import "@ember/optional-features/-embroider-implicit-modules.js";
import "@embroider/macros/-embroider-implicit-modules.js";
import "@ember/render-modifiers/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "@ember/test-helpers/-embroider-implicit-modules.js";
import "ember-inflector/-embroider-implicit-modules.js";
import "ember-template-imports/-embroider-implicit-modules.js";
import "@glimmer/component/-embroider-implicit-modules.js";
import "@glimmer/tracking/-embroider-implicit-modules.js";
import "@onwardcare/ember-headlessui/-embroider-implicit-modules.js";
import "@sentry/ember/-embroider-implicit-modules.js";
import "ember-cli-postcss/-embroider-implicit-modules.js";
import "broccoli-asset-rev/-embroider-implicit-modules.js";
import "ember-cli-app-version/-embroider-implicit-modules.js";
import "ember-cli-clean-css/-embroider-implicit-modules.js";
import "ember-cli-clipboard/-embroider-implicit-modules.js";
import "ember-cli-dependency-checker/-embroider-implicit-modules.js";
import "ember-cli-deprecation-workflow/-embroider-implicit-modules.js";
import "ember-composable-helpers/-embroider-implicit-modules.js";
import "ember-fetch/-embroider-implicit-modules.js";
import "ember-google-maps/-embroider-implicit-modules.js";
import "ember-load-initializers/-embroider-implicit-modules.js";
import "ember-resolver/-embroider-implicit-modules.js";
import "ember-source/-embroider-implicit-modules.js";
import "ember-tooltips/-embroider-implicit-modules.js";
import "loader.js/-embroider-implicit-modules.js";
